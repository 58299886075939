<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"  >
  <p style="font-size: 20px; color: white" *ngIf="!showSSOMEssage">Enregistrement en cours...</p>
  <p style="font-size: 20px; color: white" *ngIf="showSSOMEssage">Connexion à Talensoft en cours...</p>
</ngx-spinner>

      <!--NLMK Formulaire-->
      <ng-container *ngIf="show_nlk_form">
        <div class="container">
          <div class="col-lg-12">
            <div class="jumbotron">
              <div class="title">
                <h2 class="display-4 font-weight-bold" style="text-align: center">{{forms.title}}</h2></div>
              <div class="row justify-content-center custom-line">
                <div class="col-lg-6 col-md-10 col-sm-9">
                  <h2 class="display-4 font-weight-bold" style="text-align: center">Enregistrez-vous !</h2></div>
                <div class="col-lg-4 col-md-6 col-sm-8">
                  <h2 class="display-4 font-italic" style="text-align: center">Register !</h2>
                </div>
              </div>
              <div>
                <p class="lead text-center" style="margin: 0;font-weight: bold;">Remplissez les champs suivants afin de créer ou réactiver votre compte </p>
                <p class="lead text-center font-italic" style="margin: 0;">Fill in the following fields to create or reactivate your account</p>
              </div>
              <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>

              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">N° carte d'identité</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Identity card number</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.cid" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="auto_form_registration_nlmk()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>

        </form>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="show_auto_inscription_form">
          <ng-container>
            <div class="container">
              <div class="col-lg-12">
                <div class="jumbotron">
                  <div class="row justify-content-center custom-line">
                    <div class="col-lg-6 col-md-10 col-sm-9">
                      <h2 class="display-4 font-weight-bold" style="text-align: center">Enregistrez-vous !</h2></div>
                    <div class="col-lg-6 col-md-9 col-sm-8">
                      <h2 class="display-4 font-italic" style="text-align: center">Please Register!</h2>
                    </div>
                  </div>
                  <div>
                    <p class="lead text-center" style="margin: 0;font-weight: bold;">Remplissez les champs suivants afin de créer ou réactiver votre compte </p>
                  </div>
                  <div>
                    <p class="lead text-center font-italic" style="margin: 0;">Fill in the following fields to create or reactivate your account</p>
                  </div>
                  <hr class="my-4">
            <div class="card">
              <h5 class="card-header text-uppercase text-center">Informations générales</h5>
              <h5 class="card-header text-uppercase text-center font-italic">Generals informations</h5>
              <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
              <small class="font-italic" style="text-align: center;">Fields marked with (*) are mandatory</small>
              <div class="card-body">

                <div class="form-group row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [formControl]="lastname">
                  </div>
                </div>
                <div class="form-group row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prenom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [formControl]="firstname">
                  </div>
                </div>
                <div class="form-group row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Login * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Login *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [formControl]="login">
                  </div>
                </div>
                <div class="form-group row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Mot de passe *
                          <i class="bx bx-info-circle"
                             (mouseenter)="showInfo = true"
                             (mouseleave)="showInfo = false">
                          </i>
                          <div class="info-tooltip" *ngIf="showInfo">
                            Le mot de passe doit être différent du login et doit contenir au moins UN caractère spécial, Une lettre majuscule,
                            Une lettre minuscule, UN chiffre et 9 caractères. Ce mot de passe doit également être différent de tous ceux
                            utilisés auparavant.
                          </div>
                        </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Password *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="password" [formControl]="password" >
                  </div>
                  <p *ngIf="isPasswordInvalid" class="text-danger fw-bold ml-2 mt-2">
                    Le mot de passe doit être différent du login et doit contenir au moins UN caractère spécial, UNE majuscule, UNE minuscule, UN chiffre et 9 caractères. Il doit également être différent de tous ceux utilisés auparavant.
                  </p>
                </div>
                <div class="form-group row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Confirmer le mot de passe * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Confirm Password *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="password" [formControl]="confirm_password" >
                  </div>
                </div>

                <div class="form-group row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">E-mail * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">E-mail *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [formControl]="email">
                  </div>
                </div>
                <ng-container *ngIf="content_form && content_form.length > 0">
                  <div class="form-group row" *ngFor="let f of content_form">
                    <div class='col-md-5'>
                      <div class="row">
                        <div class='col-md-12'>
                          <label class="font-weight-bold" style="margin-bottom: 0">{{f.name}} <span *ngIf="f.is_required && f.is_required === 1">*</span></label>
                        </div>
                        <div class='col-md-12'>
                          <label class="font-italic" style="margin-bottom: 0">{{f.translation}} <span *ngIf="f.is_required && f.is_required === 1">*</span></label>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <input class="form-control" type="{{f.inputType}}" id="{{f.ng_model_name}}" [required]="f.is_required === '1' ? '' : null">
                    </div>
                  </div>

                </ng-container>

              </div>
              <div class="form-group row">
                <div class="col-md-5 col-form-label"></div>
                <div class="col-md-7">
                  <button type="button" class="btn btn-primary" (click)="auto_form_registration_other()">Valider (Validate!)</button>
                </div>
              </div>

            </div>
                  <div class="text-center mb-2">
                    Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
                    KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
                    Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
                    Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
                    Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
                  </div>
                  <div class="font-italic text-center">
                    The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
                    KAPTITUDE respects the European regulations in force (GDPR).
                    Your data will only be used for training delivery purposes.
                    You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
                    the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
                  </div>
            </div>
            </div>
            </div>
          </ng-container>
      </ng-container>
<ng-container *ngIf="show_auto_inscription_form_BASF">
  <div class="container">
    <div class="col-lg-12">
      <div class="jumbotron">
        <div class="title">
          <h2 class="display-4 font-weight-bold" style="text-align: center">{{forms.title}}</h2></div>
        <div><h2 class="" style="text-align: center">{{forms.label}}</h2></div>
        <div class="row justify-content-center custom-line">
          <div class="col-lg-6 col-md-10 col-sm-9">
            <h2 class="display-4 font-weight-bold" style="text-align: center">Enregistrez-vous !</h2></div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <h2 class="display-4 font-italic" style="text-align: center">Register !</h2>
          </div>
        </div>
        <div>
          <p class="lead text-center" style="margin: 0;font-weight: bold;">Remplissez les champs suivants afin de créer ou réactiver votre compte </p>
          <p class="lead text-center font-italic" style="margin: 0;">Fill in the following fields to create or reactivate your account</p>
        </div>
        <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email manager</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Manager mail</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.manageremail" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="auto_form_registration_basf()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
<!--form for home security-->
<ng-container *ngIf="show_form_home_security">
  <div class="container">
    <div class="col-lg-12">
      <div class="jumbotron">
        <div class="title">
          <h2 class="display-4 font-weight-bold" style="text-align: center">{{forms.title}}</h2></div>
        <div class="row justify-content-center custom-line">
          <div class="col-lg-6 col-md-10 col-sm-9">
            <h2 class="display-4 font-weight-bold" style="text-align: center">Enregistrez-vous !</h2></div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <h2 class="display-4 font-italic" style="text-align: center">Register !</h2>
          </div>
        </div>
        <div>
          <p class="lead text-center" style="margin: 0;font-weight: bold;">Remplissez les champs suivants afin de créer ou réactiver votre compte </p>
          <p class="lead text-center font-italic" style="margin: 0;">Fill in the following fields to create or reactivate your account</p>
        </div>
        <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email manager</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Manager mail</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.manageremail" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="auto_form_registration_basf()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>



<!--Formulaire ORIL 1- Accueil Sécurité ORIL-->
<ng-container *ngIf="show_auto_inscription_form_ORIL_FR">
  <div class="container">
    <div class="col-lg-12">
      <div class="jumbotron">
        <div class="title">
          <h2 class="display-4 font-weight-bold" style="text-align: center">{{forms.title}}</h2></div>
        <div class="row justify-content-center custom-line">
          <div class="col-lg-6 col-md-10 col-sm-9">
            <h2 class="display-4 font-weight-bold" style="text-align: center">Enregistrez-vous !</h2></div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <h2 class="display-4 font-italic" style="text-align: center">Register !</h2>
          </div>
        </div>
        <div>
          <p class="lead text-center" style="margin: 0;font-weight: bold;">Remplissez les champs suivants afin de créer ou réactiver votre compte </p>
          <p class="lead text-center font-italic" style="margin: 0;">Fill in the following fields to create or reactivate your account</p>
        </div>
        <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email manager *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Manager mail</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.manageremail" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="auto_form_registration_ORIL_FR()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<!--Formulaire ORIL 2- Safety Instructions ORIL-->

<ng-container *ngIf="show_auto_inscription_form_ORIL_EN">
  <div class="container">
    <div class="col-lg-12">
      <div class="jumbotron">
        <div class="title">
          <h2 class="display-4 font-weight-bold" style="text-align: center">{{forms.title}}</h2></div>
        <div class="row justify-content-center custom-line">
          <div class="col-lg-6 col-md-10 col-sm-9">
            <h2 class="display-4 font-weight-bold" style="text-align: center">Enregistrez-vous !</h2></div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <h2 class="display-4 font-italic" style="text-align: center">Register !</h2>
          </div>
        </div>
        <div>
          <p class="lead text-center" style="margin: 0;font-weight: bold;">Remplissez les champs suivants afin de créer ou réactiver votre compte </p>
          <p class="lead text-center font-italic" style="margin: 0;">Fill in the following fields to create or reactivate your account</p>
        </div>
        <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email manager *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Manager mail</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.manageremail" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="auto_form_registration_ORIL_EN()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<!--Formulaire CEA PARIS-SACLAY- AS Saclay-->

<ng-container *ngIf="show_form_AS_SACLAY">
  <div class="container">
    <div class="col-lg-12">
      <div class="jumbotron" style="padding-top: 3rem;">
        <div class="title mb-4">
          <h2 class="h2 font-weight-bold text-center">{{forms.title}}</h2>
        </div>

        <div class="container mb-4">
            <h1 class="h1 font-weight-bold text-center" style="font-size: 4em;margin-bottom: 0px;">Créez ou réactivez votre compte !</h1>
            <h1 class="h1 font-italic text-center">Create or reactivate your account !</h1>
        </div>

        <div>
          <p class="text-center font-weight-bold" style="margin: 0;">Remplissez les champs suivants</p>
          <p class="text-center font-italic" style="margin: 0;">Fill in the following fields</p>
        </div>


        <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Unité *</label>
                        <label class="element-avec-bulle">
                        <i  class="fas fa-info-circle"
                           (mouseenter)="afficherInfoBulle = true"
                           (mouseleave)="afficherInfoBulle = false">
                        </i>
                        <div class="bulle-info" *ngIf="afficherInfoBulle">
                          Si vous ne
                          connaissez pas le titre de
                          votre unité, indiquez le nom
                          du chef d’installation (CI) ou
                          de l’ingénieur sécurité
                          d’installation (ISI)</div>
                      </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Unit *</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.service" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email manager *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Manager mail *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.manageremail" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">N° d'installation *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Facility number *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.numero_installation" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="form_AS_SACLAY()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<!--Formulaire CEA PARIS-SACLAY- AS FONTENAY-->

<ng-container *ngIf="show_form_AS_FONTENAY">
  <div class="container">
    <div class="col-lg-12">
      <div class="jumbotron" style="padding-top: 3rem;">
        <div class="title mb-4">
          <h2 class="h2 font-weight-bold text-center">{{forms.title}}</h2>
        </div>

        <div class="container mb-4">
          <h1 class="h1 font-weight-bold text-center" style="font-size: 4em;margin-bottom: 0px;">Créez ou réactivez votre compte !</h1>
          <h1 class="h1 font-italic text-center">Create or reactivate your account !</h1>
        </div>

        <div>
          <p class="text-center font-weight-bold" style="margin: 0;">Remplissez les champs suivants</p>
          <p class="text-center font-italic" style="margin: 0;">Fill in the following fields</p>
        </div>
        <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Unité *</label>
                        <label class="element-avec-bulle">
                          <i  class="fas fa-info-circle"
                              (mouseenter)="afficherInfoBulle = true"
                              (mouseleave)="afficherInfoBulle = false">
                          </i>
                          <div class="bulle-info" *ngIf="afficherInfoBulle">
                            Si vous ne
                            connaissez pas le titre de
                            votre unité, indiquez le nom
                            du chef d’installation (CI) ou
                            de l’ingénieur sécurité
                            d’installation (ISI)</div>
                        </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Unit *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.service" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email manager *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Manager mail *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.manageremail" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">N° d'installation *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Facility number *</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.numero_installation" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="form_AS_FONTENAY()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="show_auto_inscription_form_BASF_Lyon">
  <div class="container">
    <div class="col-lg-12">
      <div class="jumbotron">
        <div class="title">
          <h2 class="display-4 font-weight-bold" style="text-align: center">{{forms.title}}</h2></div>
        <div><h2 class="" style="text-align: center">{{forms.label}}</h2></div>
        <div class="row justify-content-center custom-line">
          <div class="col-lg-6 col-md-10 col-sm-9">
            <h2 class="display-4 font-weight-bold" style="text-align: center">Enregistrez-vous !</h2></div>
          <div class="col-lg-4 col-md-6 col-sm-8">
            <h2 class="display-4 font-italic" style="text-align: center">Register !</h2>
          </div>
        </div>
        <div>
          <p class="lead text-center" style="margin: 0;font-weight: bold;">Remplissez les champs suivants afin de créer ou réactiver votre compte </p>
          <p class="lead text-center font-italic" style="margin: 0;">Fill in the following fields to create or reactivate your account</p>
        </div>
        <hr class="my-4">
        <form>
          <div class="card">
            <h5 class="card-header text-uppercase text-center">Informations générales</h5>
            <small style="text-align: center;font-weight: bold;">Les champs avec la mention (*) sont obligatoires</small>
            <small style="text-align: center" class="font-italic">Fields marked with (*) are mandatory</small>
            <div class="card-body">
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Société * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Company </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.employeur" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Prénom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">First name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Prenom" [(ngModel)]="nlmk_user.firstname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Nom * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Last name </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" placeholder="Nom" [(ngModel)]="nlmk_user.lastname" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Date de naissance * </label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Birth date </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" style="margin-top: 0px" type="date" [(ngModel)]="nlmk_user.field20_details.datenaissance" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email *</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Email</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="email" [(ngModel)]="nlmk_user.email" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
              <div class="container-fluid" style="padding: 10px">
                <div class="row">
                  <div class='col-md-5'>
                    <div class="row">
                      <div class='col-md-12'>
                        <label class="font-weight-bold" style="margin-bottom: 0">Email manager</label>
                      </div>
                      <div class='col-md-12'>
                        <label class="font-italic" style="margin-bottom: 0">Manager mail</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7">
                    <input class="form-control" type="text" [(ngModel)]="nlmk_user.field20_details.manageremail" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="btn btn-primary" (click)="auto_form_registration_basf_lyon()">Valider</button>
          </div>
          <div class="text-center mb-2">
            Les plateformes <a href="https://certikap.com">https://certikap.com</a> et <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> sont administrées par la société KAPTITUDE.
            KAPTITUDE respecte la réglementation européenne en vigueur (RGPD).
            Vos données ne seront utilisées que pour les besoins de réalisation de prestations de formation.
            Vous disposez de droits d’accès, de rectification, d’effacement, de limitation de traitement, d’opposition au traitement et de portabilité des données qui vous concernent.
            Pour exercer ces droits, merci de contacter le service formation de Kaptitude : <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
          <div class="font-italic text-center">
            The platform <a href="https://kaptitude.elmg.net/">kaptitude.elmg.com</a> is administered by KAPTITUDE.
            KAPTITUDE respects the European regulations in force (GDPR).
            Your data will only be used for training delivery purposes.
            You have rights of access, rectification, erasure, limitation of treatment, opposition to the treatment and portability of
            the data which concern you. To exercise these rights, please contact Kaptitude Training service: <a href="mailto:formateur@kaptitude.com">formateur@kaptitude.com</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>

<app-footer></app-footer>
