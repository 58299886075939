<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active"> Mon profil
                  <ng-container *ngIf="profile_id==1">
                    Administrateur
                  </ng-container>
                  <ng-container *ngIf="profile_id==12">
                    Client
                  </ng-container>
                  <ng-container *ngIf="profile_id==6">
                    Superviseur
                  </ng-container>
                  <ng-container *ngIf="profile_id==14">
                    Intervenant/Apprenant
                  </ng-container>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <!-- end row -->
      <div class="row">
          <div class="card col-xl-12">
            <div class="card-body">
              <h4 class="card-title mb-4">Mon profil</h4>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Prénom :</label>
                <div class="col-md-10">
                  <input type="text" class="form-control"  placeholder="Prénom"  value="{{this.single_user.firstname}}" [(ngModel)]="single_user.firstname" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Nom :</label>
                <div class="col-md-10">
                  <input type="text" class="form-control"  placeholder="Nom"  value="{{this.single_user.name}}" [(ngModel)]="single_user.name" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">E-mail :</label>
                <div class="col-md-10">
                  <input type="email" class="form-control"  placeholder="Email" value="{{this.single_user.email}}" [(ngModel)]="single_user.email" [ngModelOptions]="{standalone: true}">
                </div>
              </div>
              <div class="form-group row">
                <div class="input-group">
                  <label for="password" class="col-md-2 col-form-label">Mot de passe :
                      <i class="bx bx-info-circle"
                         (mouseenter)="showInfo = true"
                         (mouseleave)="showInfo = false">
                      </i>
                      <div class="info-tooltip" *ngIf="showInfo">
                        Le mot de passe doit être différent du login et doit contenir au moins UN caractère spécial, Une lettre majuscule,
                        Une lettre minuscule, UN chiffre et 9 caractères. Ce mot de passe doit également être différent de tous ceux
                        utilisés auparavant.
                      </div>
                  </label>
                  <input type="password" name="password" id="password" #password1 class="col-md-10 form-control"
                         style="margin-left: 12px;border-radius: 5px;" placeholder="Mot de passe" value=""
                         [(ngModel)]="single_user.password" [ngModelOptions]="{standalone: true}">
                  <div class="input-group-append" style="margin-right: 11px;border-radius: 5px !important;"
                       (click)="changeType(password1, 1)">
                    <span class="input-group-text"><i [ngClass]="toggle1 ? 'fas fa-eye' : 'fas fa-eye-slash'"></i></span>
                  </div>
                </div>
                <p class="font-italic mb-0">Laissez le champ mot de passe vide, si vous ne souhaitez pas le modifier.</p>
                <p *ngIf="isPasswordInvalid" class="text-danger fw-bold ml-2 mt-2">
                  Le mot de passe doit être différent du login et doit contenir au moins UN caractère spécial, UNE majuscule, UNE minuscule, UN chiffre et 9 caractères. Il doit également être différent de tous ceux utilisés auparavant.
                </p>
              </div>
              <div class="form-group row">
                <div class="input-group">
                  <label for="c_password" class="col-md-2 col-form-label">Confirmation : </label>
                  <input type="password" name="c_password" id="c_password" #password2 class="col-md-10 form-control" style="margin-left: 12px;border-radius: 5px;" placeholder="Confirmer votre mot de passe"  value="" [(ngModel)]="single_user.password_confirm" [ngModelOptions]="{standalone: true}">
                  <div class="input-group-append" style="margin-right: 11px;border-radius: 5px !important;" (click)="changeType(password2, 2)">
                    <span class="input-group-text"><i [ngClass]="toggle2 ? 'fa fa-eye' : 'fa fa-eye-slash'"></i></span>
                  </div>
                </div>
                <p class="font-italic">Laissez le champ confirmez mot de passe vide, si vous ne souhaitez pas le modifier.</p>
              </div>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">Identifiant :</label>
                <div class="col-md-10">
                  <input class="form-control" type="text" [(ngModel)]="single_user.login" readonly>
                </div>
              </div>
              <button class="blue_btn" (click)="update_profile()" class="btn btn-primary waves-effect waves-light" >Valider</button>
            </div>
          </div>
          <!-- end card -->
          <!-- end card -->
        </div>

      </div>
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
  <!-- End Page-content -->
  <app-footer></app-footer>
