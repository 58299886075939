import * as i0 from "@angular/core";
export class PasswordService {
    constructor() { }
    // Fonction de vérification du mot de passe
    verifyPassword(input) {
        if (input.length < 9) {
            return false;
        }
        if (!/[A-Z]/.test(input)) {
            return false;
        }
        if (!/[a-z]/.test(input)) {
            return false;
        }
        if (!/\d/.test(input)) {
            return false;
        }
        if (!/[\W_]/.test(input)) {
            return false;
        }
        return true;
    }
}
PasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordService_Factory() { return new PasswordService(); }, token: PasswordService, providedIn: "root" });
