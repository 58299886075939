<ngx-spinner></ngx-spinner>
<section id="front_wrap">
  <section id="connect_page">
    <div id="connect_title">
      <ng-container *ngIf="text_login">
        <p>Mot de passe oublié</p>
      </ng-container>
      <ng-container *ngIf="text_new_password">
        <p>Nouveau mot de passe</p>
      </ng-container>

    </div>
    <div id="connect_content">
      <form>
        <ng-container *ngIf="text_login">
          <input type="email"  placeholder="Votre identifiant" [(ngModel)]="email_send" [ngModelOptions]="{standalone: true}">
          <button class="blue_btn"  type="button" color="accent" (click)="send_new_password()">Envoyer</button>
          <p style="margin-top: 25px"><a href="/"><< Se connecter</a></p>
        </ng-container>
        <ng-container *ngIf="text_new_password">
          <input class="mb-0" type="password"  placeholder="Nouveau mot de passe" [(ngModel)]="new_password" [ngModelOptions]="{standalone: true}">
          <div class="col-md-12 mt-3 mb-3">
            <p [ngClass]="{'text-danger': isPasswordInvalid}" class="text-danger fw-bold ml-2 mt-2">
              Le mot de passe doit être différent du login et doit contenir au moins UN caractère spécial, UNE majuscule, UNE minuscule, UN chiffre et 9 caractères. Il doit également être différent de tous ceux utilisés auparavant.
            </p>
          </div>
          <button class="blue_btn"  type="button" color="accent" (click)="change_password()">Changer</button>
          <p style="margin-top: 25px"><a href="/"><< Se connecter</a></p>
        </ng-container>
      </form>
    </div>
    <div id="connect_legal">
      <p>Institut PASTEUR - par <strong>Kaptitude</strong> - 2021</p>

    </div>
  </section>
</section>
