import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor() { }


  // Fonction de vérification du mot de passe
  verifyPassword(input: string): boolean {
    if (input.length < 9) {
      return false;
    }
    if (!/[A-Z]/.test(input)) {
      return false;
    }
    if (!/[a-z]/.test(input)) {
      return false;
    }
    if (!/\d/.test(input)) {
      return false;
    }
    if (!/[\W_]/.test(input)) {
      return false;
    }
    return true;
  }
}


